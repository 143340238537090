import React, { useEffect, useState } from 'react'

import format from '../../../hooks/formatCoins'

import { Icon24DismissSubstract, Icon28DoneOutline } from '@vkontakte/icons'

const PenaltyBetBoardButtons = ({
  newBet,
  newBets,
  imputValue,
  setImputValue,
  user, goPenaltyGame
}) => {
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (disabled === true) {
      setTimeout(() => {
        setDisabled(false)
      }, 2000)
    }
  }, [disabled])

  return (
    <div className="penalty-bet-board-inp">
      <div className="score-board-bet-fc-header">
        <div>ВВЕДИТЕ СТАВКУ</div>
      </div>
      <div className="bet-board-fc-menu">
        <div className="bet-fc-menu-ul">
          <div onClick={() => {newBet(100000)}} >+100К</div>
          <div onClick={() => {newBet(1000000)}}>+1КК</div>
          <div onClick={() => {newBet(10000000)}}>+10КК</div>
          <div onClick={() => {newBet(-1000000)}}>-1КК</div>
        </div>
        <div className="bet-fc-input-ul">
          <input
            maxLength="10"
            id="football-bet"
            value={format(imputValue)}
            onChange={() => {
              let num = document.getElementById('football-bet')
              setImputValue(+num.value.replace(/[^\d]/g, '').replace(/\s/g, ''))
            }} />
          <div onClick={() => {newBets(1)}}> x2</div>
          <div onClick={() => {newBets(2)}}>/2</div>
        </div>
      </div>

      <div
        className="bet-fs-button"
        onClick={() => {
          if (disabled) return
          goPenaltyGame()
          setDisabled(true)
        }}
      >
        <Icon28DoneOutline style={{ marginRight: 5 }} />
        <div>УДАР</div>
      </div>
    </div>
  )
}

export default PenaltyBetBoardButtons
