import React, {useState, useEffect} from 'react'

import { Icon24DismissSubstract } from '@vkontakte/icons';
import { Icon20FireCircleFillRed } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge'

import request from '../../../hooks/useHttp'
import './FreeCase.css'

const FreeCase = (props) => {
    const [body, setBody] = useState(null)
    const [footer, setFooter] = useState(null)

    const getBonus = async (i) => {
        try {
            let req = await request('get_case_1', 'POST', {
                url: document.location.search,
                prize: i
              })

            const cases = []

            for (let z = 0; z < 9; z++) {
                let color = null
                z === i ? color = 'green' : null
                cases.push(
                    <div className='free-case-game-cases' key={z} style={{background: color}}>
                        <div>{req.prizes[z]}</div>
                    </div>
            )}
                setBody(
                    <div className="free-case-game">
                        <div className="free-case-game-body">
                            <div className="free-case-game-body-header">
                            <p>Ваш результат</p>
                            <Icon24DismissSubstract fill="red" onClick={() => {
                                setBody(null)
                                setFooter(null)
                                bridge.sendPromise('VKWebAppShowNativeAds', { ad_format: 'interstitial' })
                            }}/>
                            </div>
                            <div className='free-case-game-icons'>
                                {cases}
                            </div>
                            <div className="free-case-game-footer" style={{color: 'rgb(0, 255, 0)'}}>{`Вы выиграли ${req.money} VK Coin`}</div>
                        </div>
                    </div>
                    )
        } catch (e) {
            setFooter(e.message)
        }
    }

    const startCaseGame = () => {

        const cases = []

        for (let i = 0; i < 9; i++) {
            cases.push(
            <div className='free-case-game-cases' key={i} onClick={() => {
                getBonus(i)
            }}>
                <div>🎁</div>
            </div>
            )
        }

        setBody(
            <div className="free-case-game">
                <div className="free-case-game-body">
                    <div className="free-case-game-body-header">
                    <p>Выберите кейс</p>
                    <Icon24DismissSubstract fill="red" onClick={() => {
                        setBody(null)
                        setFooter(null)
                        bridge.sendPromise('VKWebAppShowNativeAds', { ad_format: 'interstitial' })
                    }}/>
                    </div>
                    <div className='free-case-game-icons'>
                        {cases}
                    </div>
                    <div className="free-case-game-footer">{footer}</div>
                </div>
                    
            </div>

        )
    }

    useEffect(() => {
        if (!footer) return
        startCaseGame()
    }, [footer])

    return (
    <div className="free-case-body">
        <div className="profile-bonus-button" onClick={startCaseGame}>
        <Icon20FireCircleFillRed width={24} height={24}/>
        <p>ОТКРЫТЬ КЕЙС</p>
        </div>
        {body}
    </div>
    )
}

export default FreeCase