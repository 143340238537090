import React from 'react'

import { Panel, PanelHeader } from '@vkontakte/vkui'
import '../css/WarningPanel.css'

const WarningPanel = ({ id, goMenu }) => {
  return (
    <Panel id={id}>
      <PanelHeader>Начало</PanelHeader>
      <div className="warning_menu">
        <div className="warning_information">
          <p>ВНИМАНИЕ</p>
          <ul>
            <li>
              Приложение Football Coin создано исключительно для развлечения
            </li>
            <li>
              Для игры используется игровая валюта VK Coin не имеющая
              материальной ценности
            </li>
            <li>
              Для получения игровой валюты достаточно каждые 15 минут нажимать
              на кнопку "Получить бонус"
            </li>
            <li>
              Приобрести игровую валюту в приложении невозможно, а так же
              запрещена любая продажа и обмен на материальные ценности!
            </li>
            <li>
              Получать VK Coin вы так же можете учавствуя в ивентах, раздачах и
              открывая бесплатные кейсы!
            </li>
            <li>Удачной игры!</li>
          </ul>
        </div>
        <div
          className="warning_button"
          onClick={() => {
            goMenu('main-menu')
          }}
        >
          Понятно!
        </div>
      </div>
    </Panel>
  )
}

export default WarningPanel
