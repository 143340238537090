import React from 'react'

import { Panel, PanelHeader } from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'
import '../css/Games.css'
import { Link } from '@vkontakte/vkui'

const Top = (props) => (
  <Panel id={props.id}>
    <PanelHeader>Игры</PanelHeader>
{props.fetchedUser.id === 431035213
? <div
      className="bouncing-ball-game-menu-item"
      onClick={() => {
        if (props.fetchedUser.id != 431035213 && props.fetchedUser.id != 565163285) return
        props.goGame('bouncing-ball-game')
        props.goMenu('game')
      }}
    >
      <div className='game-item-fon'>
        <p>Bouncing Ball</p>
      </div>
    </div> : null}

    {props.fetchedUser.id === 431035213 ?
    <div
      className="penalty_pvp-coin-game-menu-item"
      onClick={() => {
        props.goGame('penalty_pvp-game')
        props.goMenu('game')
      }}
    >
    <div className='game-item-fon'>
      <p>Пенальти pvp</p>
    </div>
    </div> : null}

    <Link href={`https://vk.com/app8175004`} target="_blank">
      <div
        className="football_quiz-coin-game-menu-item"
        onClick={() => {
          props.goGame('football_quiz-game')
        }}>
        <div className='game-item-fon'>
          <p>Football Quiz</p>
        </div>
      </div> 
    </Link>

    <div
      className="football-coin-game-menu-item"
      onClick={() => {
        props.goGame('football-game')
        props.goMenu('game')
      }}
    >
      <div className='game-item-fon'>
        <p>football coin</p>
      </div>
    </div>
    <div className="game-grid">
      <div
        className="luckyspin-coin-game-menu-item"
        onClick={() => {
          props.goGame('luckyspin-game')
          props.goMenu('game')
        }}
      >
      <div className='game-item-fon'>
        <p>Lucky Spin</p>
      </div>
      </div>
      <div
        className="luckyball-coin-game-menu-item"
        onClick={() => {
          props.goGame('luckyball-game')
          props.goMenu('game')
        }}
      >
      <div className='game-item-fon'>
        <p>Lucky Ball</p>
      </div>
      </div>
      <div
        className="penalty-coin-game-menu-item"
        onClick={() => {
          props.goGame('penalty-game')
          props.goMenu('game')
        }}
      >
      <div className='game-item-fon'>
        <p>Пенальти</p>
      </div>
      </div>
      <div
        className="ice-cube-coin-game-menu-item"
        onClick={() => {
            props.goGame('ice-cube-game')
            props.goMenu('game')
        }}
      >
      <div className='game-item-fon'>
        <p>Ice Cube</p>
      </div>
      </div>
    </div>
  
  </Panel>
)

export default Top
