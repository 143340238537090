import React, { useRef, useEffect, useState } from 'react'

import { Panel, PanelHeader, Group, Footer, Spinner } from '@vkontakte/vkui'
import format from '../hooks/formatCoins'
import request from '../hooks/useHttp'

import '../css/Statistic.css'


const Statistic = (props) => {
  const statistic = useRef()
  const interval = useRef(null)
  const [statisticItem, setStatisticItem] = useState(
    <Spinner size="medium" style={{ margin: '20px 0' }} />
  )
  const setStatistic = (x) => {
    statistic.current = x
  }

  useEffect(() => {
    interval.current = setInterval(async () => {
      const req = await request('get_statistic', 'POST', {
        url: document.location.search,
      })
      setStatistic(req.info)
      setItem()
 
    }, 1000)

    return () => {
        clearInterval(interval.current)
    }
  }, [])

  const setItem = () => {
    setStatisticItem(
      <Group separator="hide">
        <div className="statistic-card">
          <div className="statistic-card-element ">
            <div>Общая сумма ставок</div>
            <p>
              {format(
                (
                  statistic.current.football_bets +
                    statistic.current.lucky_bets +
                    statistic.current.penalty_bets +
                    statistic.current.luckyspin_bets +
                    statistic.current.sapper_bets || 0
                ).toFixed(0)
              )}
            </p>
          </div>
          <div className="statistic-card-element ">
            <div>Всего игр</div>
            <p>
              {format(
                statistic.current.football_games +
                  statistic.current.lucky_games +
                  statistic.current.penalty_games +
                  statistic.current.luckyspin_games +
                  statistic.current.sapper_games || 0
              )}
            </p>{' '}
          </div>
          <div className="statistic-card-element ">
            <div>Игры в FC</div>
            <p>{format(statistic.current.football_games || 0)} </p>
          </div>
          <div className="statistic-card-element ">
            <div>Игры в Lucky Ball</div>
            <p>{format(statistic.current.lucky_games || 0)} </p>
          </div>
          <div className="statistic-card-element ">
            <div>Игры в Пенальти</div>
            <p>{format(statistic.current.penalty_games || 0)} </p>
          </div>
          <div className="statistic-card-element ">
            <div>Игры в Lucky Spin</div>
            <p>{format(statistic.current.luckyspin_games || 0)} </p>
          </div>
          <div className="statistic-card-element ">
            <div>Игры в Sapper Ball</div>
            <p>{format(statistic.current.sapper_games || 0)} </p>
          </div>
          <div className="statistic-card-element ">
            <div>Игры в Кейсы</div>
            <p>{format(statistic.current.free_case_games || 0)} </p>
          </div>
          <div className="statistic-card-element ">
            <div>Ставки на FC</div>
            <p>
              {format((statistic.current.football_bets * 1 || 0).toFixed(0))}{' '}
            </p>
          </div>
          <div className="statistic-card-element ">
            <div>Ставки на Lucky Ball</div>
            <p>{format((statistic.current.lucky_bets * 1 || 0).toFixed(0))} </p>
          </div>
          <div className="statistic-card-element ">
            <div>Ставки на Пенальти</div>
            <p>
              {format((statistic.current.penalty_bets * 1 || 0).toFixed(0))}{' '}
            </p>
          </div>
          <div className="statistic-card-element ">
            <div>Ставки на Lucky Spin</div>
            <p>
              {format((statistic.current.luckyspin_bets * 1 || 0).toFixed(0))}{' '}
            </p>
          </div>
          <div className="statistic-card-element ">
            <div>Ставки на Sapper Ball</div>
            <p>
              {format((statistic.current.sapper_bets * 1 || 0).toFixed(0))}{' '}
            </p>
          </div>
          <div className="statistic-card-element ">
            <div>Выиграли в кейсах</div>
            <p>
              {format((statistic.current.free_case_bets * 1 || 0).toFixed(0))}
            </p>
          </div>
        </div>
        <div className="all-atate-card" style={{ marginTop: 10 }}>
          Сейчас онлайн: {statistic.current.online || 0}
          <br />
          Всего чатов с ботом: {statistic.current.chats || 0}
        </div>
        <Footer style={{ color: 'white' }}>Football Coin 2.0.0</Footer>
      </Group>
    )
  }

  return (
    <Panel id={props.id}>
      <PanelHeader>Статистика</PanelHeader>
      {statisticItem}
    </Panel>
  )
}

export default Statistic
