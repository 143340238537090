import React, {useEffect, useState} from 'react'

import bridge from '@vkontakte/vk-bridge'
import format from '../../../hooks/formatCoins'

import {Link, Cell, Avatar} from '@vkontakte/vkui'

const colors = ["var(--win-red-results)", "var(--win-green-results)"]

const PenaltyBetsBoard = ({ game }) => {

  const [body, setBody] = useState(null)
  const [avatar, setAvatar] = useState([])
  const [usersPhotos, setUsersPhotos] = useState({ response: [] })

  const start = async () => {
    if (!game.bets) return
    let ids = ''
    let idsx = []
    let hist = []
    game.bets.map((x) => {
      ids += `${x.id}, `
      idsx.push(x.id)
    })

    if (avatar.length < idsx.length + 1) {
      const token = await bridge.send('VKWebAppGetAuthToken', {
        app_id: 7599871,
        scope: '',
      })

      const photos = await bridge.sendPromise('VKWebAppCallAPIMethod', {
        method: 'users.get',
        request_id: '324nnefj',

        params: {
          v: '5.95',
          user_ids: ids,
          fields: 'photo_200',
          access_token: token.access_token,
        },
      })
      await setAvatar(idsx)
      await setUsersPhotos(photos)
    }

    for (let i = game.bets.length - 1; i >= 0; i--) {
      let user = usersPhotos.response.find((x) => x.id === game.bets[i].id)
      if (!user) return console.log(1);

      let wwin = `-${format((+game.bets[i].bet * 1).toFixed(0))}`
      if (game.bets[i].win === 1) { wwin = `+${format((+game.bets[i].bet * 2.8).toFixed(0))}` }
      
      let moneyxx = (<p
              style={{
                margin: 0,
                fontWeight: 500,
                color: colors[game.bets[i].win]
              }}
            >{wwin}</p>
          )

          let user_win = `X2.8`
          if (game.bets[i].win === 0) {user_win = `X0`}

      hist.push(
        <Link
          href={`https://vk.com/id${game.bets[i].id}`}
          key={i}
          target="_blank"
          className="link-item-bets"
        >
          <div className="bets-history">
            <Cell
              className="bets-history-flex"
              before={
                user.photo_200 ? (
                  <Avatar src={user.photo_200} size={36} />
                ) : null
              }
              description={<div style={{ color: 'white' }}>{moneyxx}</div>}
            >
              <div className="bet-user-tag">{game.bets[i].tag}</div>
            </Cell>
            <div
              className="bets-history-res"
              style={{
                color: colors[game.bets[i].win],
                fontSize: "18px",
                fontWeight: 400
              }}
            >
              {user_win}
            </div>
          </div>
        </Link>
      )
    }
    setBody(hist)
  }

  useEffect(() => {

    start()
    
  }, [game.bets])

  return (
    <div
      style={{ paddingBottom: 'calc(var(--safe-area-inset-bottom) + 55px)' }}
    >
      {body}
      <div className="game-hash">
        🔐 {game.result ? game.result.hash : null}
      </div>
    </div>
  )
}

export default PenaltyBetsBoard
