import React, { useState, useEffect } from 'react'
import bridge from '@vkontakte/vk-bridge'
import {
  View,
  Epic,
  Tabbar,
  TabbarItem,
  ScreenSpinner,
  Root
} from '@vkontakte/vkui'
import '@vkontakte/vkui/dist/vkui.css'
import './css/App.css'

import WarningPanel from './panels/WarningPanel'
import ErrorPanel from './panels/ErrorPanel'
import Home from './panels/Home'
import Messenger from './panels/Messenger'
import Games from './panels/Games'
import Top from './panels/Top'
import Statistic from './panels/Statistic'

import Football from './panels/games/footballcoin/Football'
import LuckySpin from './panels/games/luckyspin/LuckySpin'
import LuckyBall from './panels/games/luckyball/LuckyBall'
import Penalty from './panels/games/penalty/Penalty'
import BouncingBall from './panels/games/bouncing-ball/BouncingBall'
import IceCube from './panels/games/IceCube/IceCube'
import PenaltyPvp from './panels/games/PenaltyPvp/PenaltyPvp'

import request from './hooks/useHttp'

import balance_fon from './img/balance-fon.jpg'
import penalty from './img/penalty.jpg'
import ls from './img/ls.jpg'
import lb from './img/lb.jpg'
import football from './img/football.jpg'
import iceCube from './img/ice-cube.png'
import ok from './img/ok.png'
import not_ok from './img/not-ok.png'

import {
  Icon28Game,
  Icon28MessageOutline,
  Icon28UserCircleOutline,
  Icon28PollSquareOutline,
  Icon28GlobeOutline,
} from '@vkontakte/icons/'
import { Icon28SettingsOutline } from '@vkontakte/icons';
import Repost from './panels/Repost'

const App = () => {
  const [activeView, setActiveView] = useState('home')
  const [fetchedUser, setUser] = useState(null)
  const [popout, setPopout] = useState(<ScreenSpinner size="large" />)
  const [userState, setUserState] = useState(null)
  const [activeMenu, setActiveMenu] = useState('warning-menu')
  const [activeGame, setActiveGame] = useState('football')

  useEffect(() => {
    async function fetchData() {
      const user = await bridge.send('VKWebAppGetUserInfo')
      setUser(user)
      setPopout(null)

      let req = await request('get_user', 'POST', {
        url: document.location.search,
      })

      await setUserState({
        user: req.user,
        clan: req.clan,
        online: req.online,
      })
    }
    fetchData()
  }, [])


  const goView = (e) => {
    setActiveView(e.currentTarget.dataset.story)
  }

  const goMenu = (e) => {
    setActiveMenu(e)
  }

  const goGame = (e) => {
    setActiveGame(e)
  }

    
  const closeApp = () => {
    bridge.send("VKWebAppClose", {"status": "success", "payload": {"name": "test"} });
  }

  if (document.location.hash) {
    if (document.location.hash.split('_')[0] === '#repost') {
      return <Repost closeApp={closeApp}/>
    }
  }

  return (
    <Root activeView={activeMenu}>
      <View id="warning-menu" activePanel="warning-panel">
        <WarningPanel id="warning-panel" goMenu={goMenu} />
      </View>
      <View id="error" activePanel="error-panel">
        <ErrorPanel id="error-panel" goMenu={goMenu} />
      </View>
      <View id="main-menu">
        <Epic
          activeStory={activeView}
          tabbar={
            <Tabbar>
              <TabbarItem
                onClick={goView}
                selected={activeView === 'home'}
                data-story="home"
                text="Профиль"
              >
                <Icon28UserCircleOutline />
              </TabbarItem>
              <TabbarItem
                onClick={goView}
                selected={activeView === 'messenger'}
                data-story="messenger"
                text="Настройки"
              >
                <Icon28SettingsOutline />
              </TabbarItem>
              <TabbarItem
                onClick={goView}
                selected={activeView === 'games'}
                data-story="games"
                text="Играть"
              >
                <Icon28Game />
              </TabbarItem>
              <TabbarItem
                onClick={goView}
                selected={activeView === 'top'}
                data-story="top"
                text="Топ"
              >
                <Icon28PollSquareOutline />
              </TabbarItem>
              <TabbarItem
                onClick={goView}
                selected={activeView === 'statistic'}
                data-story="statistic"
                text="Статистика"
              >
                <Icon28GlobeOutline />
              </TabbarItem>
            </Tabbar>
          }
        >
          <View id="home" activePanel="home" popout={popout}>
            <Home id="home" fetchedUser={fetchedUser} />
          </View>
          <View id="messenger" activePanel="messenger" popout={popout}>
            <Messenger
              id="messenger"
              fetchedUser={fetchedUser}
              user={userState}
              setPopout={setPopout}
            />
          </View>
          <View id="games" activePanel="games" popout={popout}>
            <Games
              id="games"
              goMenu={goMenu}
              activeGame={activeGame}
              goGame={goGame}
              userState={userState}
              fetchedUser={fetchedUser}
            />
          </View>
          <View id="top" activePanel="top" popout={popout}>
            <Top id="top" fetchedUser={fetchedUser} />
          </View>
          <View id="statistic" activePanel="statistic" popout={popout}>
            <Statistic id="statistic" />
          </View>
        </Epic>
      </View> 
      <View id="game" goMenu={goMenu} activePanel={activeGame}>
        <BouncingBall
          id="bouncing-ball-game"
          goMenu={goMenu}
          fetchedUser={fetchedUser}
          user={userState}
        />
        <Football
          id="football-game"
          goMenu={goMenu}
          fetchedUser={fetchedUser}
          user={userState}
        />
        <LuckySpin
          id="luckyspin-game"
          goMenu={goMenu}
          fetchedUser={fetchedUser}
          user={userState}
        />
        <LuckyBall
          id="luckyball-game"
          goMenu={goMenu}
          fetchedUser={fetchedUser}
          user={userState}
        />
        <Penalty
          id="penalty-game"
          goMenu={goMenu}
          fetchedUser={fetchedUser}
          user={userState}
        />
        <IceCube
          id="ice-cube-game"
          goMenu={goMenu}
          fetchedUser={fetchedUser}
          user={userState}
        />
         <PenaltyPvp
          id="penalty_pvp-game"
          goMenu={goMenu}
          fetchedUser={fetchedUser}
          user={userState}
        />
      </View>
        <div style={{ height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{ display: 'none'}}>
            <img src={balance_fon} alt='0'/>
            <img src={penalty} alt='0'/>
            <img src={ls} alt='0'/>
            <img src={lb} alt='0'/>
            <img src={football} alt='0'/>
            <img src={ok} alt='0'/>
            <img src={not_ok} alt='0'/>
            <img src={iceCube} alt='0'/>
          </div>
          <ScreenSpinner />
        </div>
    </Root>
  )
}

export default App
