import React, { useEffect, useState, useRef } from 'react'

import {
  Panel,
  PanelHeader,
  PanelHeaderButton,
  HorizontalScroll,
  Link,
} from '@vkontakte/vkui'

import GameUserProfile from '../../items/profile/GameUserProfile'
import GameFootballBoard from './GameFootballBoard'
import GameFootballBetsBoard from './GameFootballBetsBoard'
import GameFootballBet from './GameFootballBet'
import GameMessanger from '../../items/gameMessanger/GameMessanger'

import { Icon28ChevronBack } from '@vkontakte/icons'
import request from '../../../hooks/useHttp'

import './Football.css'

const Football = (props) => {
  const [game, setGamme] = useState({})
  const [history, setHistory] = useState(null)
  const interval = useRef(null)

  const setHistoryBlock = (game) => {
    if (!game.history) return
    let col = ''

    let histx = []
    for (let i = game.history.length - 1; i >= 0; i--) {
      let x = game.history[i]
      if (+x.goal1 < +x.goal2) {
        col = 'var(--win-red-results)'
      }
      if (+x.goal1 > +x.goal2) {
        col = 'var(--win-blue-results)'
      }
      if (+x.goal1 === +x.goal2) {
        col = 'var(--win-green-results)'
      }

      histx.push(
        <Link
          href={`https://vk.com/app7433551#${x.key}`}
          target="_blank"
          key={Math.random() * 1000000000}
        >
          <div className="history-game-fc-block" style={{ background: col }}>
            <p>
              {x.goal1} : {x.goal2}
            </p>
          </div>
        </Link>
      )
    }
    return histx
  }

  useEffect(() => {
    interval.current = setInterval(async () => {
      const info = await request('status_fc', 'POST', {
        url: document.location.search,
      })
      setGamme({
        result: info.result,
        user: info.user,
        bets: info.bets,
        history: info.history,
        messages: info.messages,
      })
      setHistory(setHistoryBlock(info))
    }, 500)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return (
    <Panel id={props.id}>
      <PanelHeader
        left={
          <PanelHeaderButton
            onClick={() => {
              props.goMenu('main-menu')
            }}
          >
            <Icon28ChevronBack fill="white" />
          </PanelHeaderButton>
        }
      >
        Football
      </PanelHeader>

      <GameUserProfile game={game} fetchedUser={props.fetchedUser} />

      <div size="m" mode="shadow" className="football-result-card">
        <div className="football-game-text">История игр</div>
        <HorizontalScroll>
          <div style={{ display: 'flex' }}>{history}</div>
        </HorizontalScroll>
      </div>

      <GameFootballBoard game={game} />
      <GameFootballBet game={game} />
      <GameFootballBetsBoard game={game} />
      <GameMessanger
        id="football-messanger"
        fetchedUser={props.fetchedUser}
        messages={game.messages}
      />
    </Panel>
  )
}

export default Football
