import React, { useEffect, useState } from "react";
import format from '../../../hooks/formatCoins'
import bridge from '@vkontakte/vk-bridge'
import { Avatar } from "@vkontakte/vkui";


let colors = ['white', 'black, red']
const PenaltyPvpGames  = ({games, startGame}) => {
    const [list, setList] = useState(null)
    let [avatars, setAvatars] = useState([])

    useEffect(() => {
        const start = async () => {

            let ids = ''
            let AVATARS = []

            console.log(games);
              games.map(x => {
                if (!avatars.find(s => s.id === x.user1)) {
                    ids += `${x.user1}, `
                }
                if (!avatars.find(s => s.id === x.user2)) {
                    if (x.user2 === 0) {
                        AVATARS.push({
                            id: 0,
                            photo_200: 'https://png.pngtree.com/png-vector/20190215/ourlarge/pngtree-vector-question-mark-icon-png-image_515448.jpg'
                            
                        })
                    } else {
                        ids += `${x.user2}, `
                    }
                }
              })


              if(ids.length > 0) {
                const token = await bridge.send('VKWebAppGetAuthToken', {
                    app_id: 7599871,
                    scope: '',
                  })
            
                const photos = await bridge.sendPromise('VKWebAppCallAPIMethod', {
                    method: 'users.get',
                    request_id: '324nnefj',
    
                    params: {
                      v: '5.95',
                      user_ids: ids,
                      fields: 'photo_200',
                      access_token: token.access_token,
                    },
                  })
    
                  console.log(photos.response);
                  photos.response.map(x => {
                    AVATARS.push({id: x.id, photo_200: x.photo_200})
                  })
    
                  setAvatars(AVATARS)
              }
        
        }
        start()
    }, [games])

    useEffect(() => {
        let LIST = []
           games.map(async x => {
              let score1 = []
              let score2 = []

              x.score1.map(x => {
                score1.push(<div className="penalty-game-results" style={{backgroundColor: colors[x]}}></div>)
              })

              x.score2.map(x => {
                score2.push(<div className="penalty-game-results" style={{backgroundColor: colors[x]}}></div>)
              })

            LIST.push(
                <div className="penalty-game-block" key={x.id}>
                    <div className="penalty-game-block-header">
                        {format(x.bet)} VK COIN
                    </div>
                    <div className="penalty-game-block-users">
                        <div className="penalty-game-block-user1">
                        <Avatar
                            src={avatars.find(s => s.id === x.user1)?.photo_200 || 'https://png.pngtree.com/png-vector/20190215/ourlarge/pngtree-vector-question-mark-icon-png-image_515448.jpg'}
                            size={30}
                            style={{ border: 'solid 2px green' }}
                        />
                        <div className="penalty-game-results-score1">{score1}</div>
                        </div>
                        <p style={{ textAlign: 'center'}}>VS</p>
                        <div className="penalty-game-block-user2">
                        <div className="penalty-game-results-score2">{score2}</div>
                        <Avatar
                            src={avatars.find(s => s.id === x.user2)?.photo_200 || 'https://png.pngtree.com/png-vector/20190215/ourlarge/pngtree-vector-question-mark-icon-png-image_515448.jpg'}
                            size={30}
                            style={{ border: 'solid 2px green' }}
                        />
                        </div>
                    </div>
                    <div className="penalty-game-block-bottom" onClick={() => {startGame(x.id)}}>
                        Присоединиться
                    </div>
                </div>
            )
           })

           setList(LIST)
    }, [avatars, games])

    return (
        <div>{list}</div>
    )
}

export default PenaltyPvpGames