import React, { useEffect, useState } from 'react'

import {
  Panel,
  PanelHeader,
  Spinner,
  Avatar,
  PanelHeaderContent,
  Footer,
} from '@vkontakte/vkui'

import request from '../hooks/useHttp'
import Profile from './items/profile/Profile.jsx'

import '../css/Home.css'

const Home = (props) => {
  const [snackBar, setSnackBar] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const letUser = async () => {
      let req = await request('get_user', 'POST', {
        url: document.location.search,
      })
      await setUser({
        user: req.user,
        clan: req.clan,
        online: req.online,
      })
    }
    letUser()
  }, [])

  let body = null
  let header = null
  if (!user) {
    body = <Spinner size="medium" style={{ margin: '20px 0' }} />
  } else {
    body = (
      <div>
        <Profile
          user={user}
          fetchedUser={props.fetchedUser}
          setSnackBar={setSnackBar}
        />
        <Footer style={{ color: '#ecf0f1' }}>Онлайн: {user.online}</Footer>
      </div>
    )
    header = (
      <PanelHeaderContent
        status={user.user.tag}
        before={
          <Avatar
            size={36}
            src={props.fetchedUser ? props.fetchedUser.photo_200 : null}
          />
        }
      >
        Профиль
      </PanelHeaderContent>
    )
  }
  return (
    <Panel id={props.id}>
      <PanelHeader left={header}></PanelHeader>
      <div>{body}</div>
      {snackBar}
    </Panel>
  )
}

export default Home
