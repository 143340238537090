import React, { useEffect, useState } from 'react'

import { Icon56RecentOutline } from '@vkontakte/icons'

import './GameIceCubeBoard.css'

const GameLuckySpinBoard = (props) => {
  const [res, setRes] = useState(null)
  useEffect(() => {
    if (props.game.result) {
      setRes(
        <div className="game-luckyspin-board-timer">
          <div>{props.game.result.time}</div>
        </div>
      )
      if (+props.game.result.time === 40) {
        setRes(
          <div>
            <Icon56RecentOutline style={{ margin: '0 auto' }} />
            <div>Ожидание ставок!</div>
          </div>
        )
      }
      if (+props.game.result.time < 0) {
        let imgs = `https://footballcoin.ru/2img/${props.game.result.number}.png`

        let col = 'black'
        props.game.result.color === 'White'
          ? (col = 'var(--luckyball-white-win)')
          : null
        setRes(
          <div className="lucky-ball-result">
            <img
              src={imgs}
              alt="ball"
              width="90px"
              height="90px"
              className="luckuball-result-img"
            />
            {}
          </div>
        )
      }
    }
  }, [props.game])

  return (
    <div className="game-iceCube-board-fon">
      <div className="game-luckyball-board">{res}</div>
    </div>
  )
}

export default GameLuckySpinBoard
