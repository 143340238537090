import React, { useState, useEffect, useRef } from 'react'

import { Panel, PanelHeader, Alert } from '@vkontakte/vkui'
import { Icon24Add, Icon24LogoVk, Icon20FavoriteCircleFillYellow, Icon20ServicesCircleFillBlue, Icon20ReplyCircleFillGreen } from '@vkontakte/icons/dist'
import '../css/Messenger.css'
import bridge from '@vkontakte/vk-bridge'

const Messenger = (props) => {

  const setAlert = (text) => {
    props.setPopout(
      <Alert
        actionsLayout="vertical"
        actions={[
          {
            title: 'Ок',
            autoclose: true,
            mode: 'cancel',
          },
        ]}
        onClose={() => {
          props.setPopout(null)
        }}
      >
        <p>{text}</p>
      </Alert>
    )
  }
  
  const goToGroup = () => {
    bridge.send('VKWebAppAddToCommunity')
  }

  return (
    <Panel id={props.id}>
      <PanelHeader>Настройки</PanelHeader>

      <div className="add-app-to-group" onClick={() => {
        bridge.send("VKWebAppShare", {"link": "vk.com/app8175004"});
      }}>
        <Icon20ReplyCircleFillGreen width={24} height={24}/>
        <div className="statistic-menu-item-state">Поделиться</div>
      </div>

      <div className="add-app-to-group" onClick={goToGroup}>
            <Icon24Add  />
          <div>Добавить в сообщество</div>
        </div>

        <a
          href="https://vk.com/football.coin"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <div className="add-app-to-group">
              <Icon24LogoVk  />
            <div>Сообщество с ботом</div>
          </div>
        </a>

        <div className="add-app-to-group" onClick={async () => {
          try {
            await bridge.send("VKWebAppAddToFavorites");
            setAlert('Мини-приложение добавлено в избранные!')
          } catch (e) {
            setAlert('Произошла неизвестная ошибка!')
          }
            }}>
          <Icon20FavoriteCircleFillYellow width={24} height={24}/>
          <div className="statistic-menu-item-state">В избранное</div>
        </div>

        <div className="add-app-to-group" onClick={async () => {
          try {
            let short = await bridge.send("VKWebAppAddToHomeScreenInfo");
            let text = ''
            let error = false
            if (short.is_added_to_home_screen) {
              text = 'Вы уже добавили приложение на главный экран устройства'
              error = true
            }
            if (!short.is_feature_supported) {
              text = 'Недоступно на вашем устройстве'
              error = true
            }
            if (short.is_feature_supported && !short.is_added_to_home_screen) {
              try {
                await bridge.send("VKWebAppAddToHomeScreen");
              } catch (e) {
                text = 'Ошибка!'
                error = true
              }
            }

            setAlert(text)
          } catch (e) {
            setAlert('Произошла неизвестная ошибка!')
          }}}>
          <Icon20ServicesCircleFillBlue width={24} height={24}/>
          <div className="statistic-menu-item-state">На главный экран</div>
        </div>

    </Panel>
  )
}

export default Messenger
