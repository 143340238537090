import React, { useState, useEffect } from 'react'

import './GameFootballBet.css'

import { Snackbar } from '@vkontakte/vkui'
import { Icon24DismissSubstract, Icon28DoneOutline } from '@vkontakte/icons'

import disImage from '../../../img/not-ok.png'

import request from '../../../hooks/useHttp'
import format from '../../../hooks/formatCoins'

const betsText = ['СИНИХ', 'КРАСНЫХ', 'НИЧЬЮ', 'ТОЧНЫЙ СЧЕТ']

const GameFootballBet = ({ game }) => {
  const [scoreBoard, setScoreBoard] = useState(null)
  const [bet, setBet] = useState(null)
  const [goals, setGoals] = useState([0, 0])
  const [imputValue, setImputValue] = useState('10000')
  const [snackBar, setSnackBar] = useState(null)

  const letGoalColorBlue = (s) => {
    if (goals[0] === s) return 'var(--win-score-results-2)'
  }

  const letGoalColorRed = (s) => {
    if (goals[1] === s) return 'var(--win-score-results-2)'
  }

  const goBetFc = async () => {
    try {
      const req = await request('new_bet_fc', 'POST', {
        url: document.location.search,
        bet: bet + 1,
        goal1: +goals[0],
        goal2: +goals[1],
        money: +document
          .getElementById('football-bet')
          .value.replace(/\s/g, ''),
      })
    } catch (e) {
      setSnackBar(
        <Snackbar
          onClose={() => setSnackBar(null)}
          before={
            <img
              src={disImage}
              alt="ok"
              width="24"
              height="24"
              align="center"
            />
          }
        >
          <div style={{ color: 'white', fontWeight: 500 }}>{e.message}</div>
        </Snackbar>
      )
    }
    setBet(null)
  }

  const newBet = (i) => {
    let x = document.getElementById('football-bet')
    let s = +x.value.replace(/\s/g, '')
    s += i
    x.value = s.toFixed(0)
    setImputValue(s)
  }

  const newBets = (i) => {
    let x = document.getElementById('football-bet')
    let s = +x.value.replace(/\s/g, '')

    if (i === 1) {
      s = s * 2
    } else {
      s = s / 2
    }

    x.value = s.toFixed(0)
    setImputValue(s)
  }

  useEffect(() => {
    let bar = null
    if (bet != null) {
      let goalBoard = null
      if (bet === 3) {
        let x = []
        let y = []

        for (let i = 0; i < 7; i++) {
          x.push(
            <div
              className="goal-bet-item"
              key={i}
              style={{ background: letGoalColorBlue(i) }}
              onClick={() => {
                setGoals([i, goals[1]])
              }}
            >
              {i}
            </div>
          )
          y.push(
            <div
              className="goal-bet-item"
              key={i}
              style={{ background: letGoalColorRed(i) }}
              onClick={() => setGoals([goals[0], i])}
            >
              {i}
            </div>
          )
        }

        goalBoard = (
          <div className="gola-board-fc">
            <div className="gola-board-fc_blue">{x}</div>
            <div className="gola-board-fc_red">{y}</div>
          </div>
        )
      }

      bar = (
        <div className="score-board-bet-fc">
          <div className="score-board-bet-fc-header">
            <div>СТАВКА НА {betsText[bet]}</div>
            <Icon24DismissSubstract
              fill="red"
              onClick={() => {
                setScoreBoard(null)
                setBet(null)
              }}
            />
          </div>
          {goalBoard}
          <div className="bet-board-fc-menu">
            <div className="bet-fc-menu-ul">
              <div
                onClick={() => {
                  newBet(100000)
                }}
              >
                +100К
              </div>
              <div
                onClick={() => {
                  newBet(1000000)
                }}
              >
                +1КК
              </div>

              <div
                onClick={() => {
                  newBet(10000000)
                }}
              >
                +10КК
              </div>
              <div
                onClick={() => {
                  newBet(-1000000)
                }}
              >
                -1КК
              </div>
            </div>
            <div className="bet-fc-input-ul">
              <input
                maxLength="10"
                id="football-bet"
                value={format(imputValue)}
                onChange={() => {
                  let num = document.getElementById('football-bet')
                  setImputValue(
                    +num.value.replace(/[^\d]/g, '').replace(/\s/g, '')
                  )
                }}
              />
              <div
                onClick={() => {
                  newBets(1)
                }}
              >
                x2
              </div>
              <div
                onClick={() => {
                  newBets(2)
                }}
              >
                /2
              </div>
            </div>
          </div>
          <div
            className="bet-fs-button"
            onClick={() => {
              goBetFc()
              setScoreBoard(null)
            }}
          >
            <Icon28DoneOutline style={{ marginRight: 5 }} />
            <div>ПОСТАВИТЬ</div>
          </div>
        </div>
      )
    }
    setScoreBoard(bar)
  }, [bet, goals, imputValue])

  return (
    <div>
      <div className="game-football-bet">
        <div
          onClick={() => {
            setBet(0)
          }}
        >
          Синие
        </div>
        <div
          onClick={() => {
            setBet(1)
          }}
        >
          Красные
        </div>
        <div
          onClick={() => {
            setBet(2)
          }}
        >
          Ничья
        </div>
        <div
          onClick={() => {
            setBet(3)
          }}
        >
          Точный счет
        </div>
      </div>
      {scoreBoard}
      {snackBar}
    </div>
  )
}

export default GameFootballBet
