import React, { useState, useEffect } from 'react'

import './GameMessanger.css'
import {
  Icon28ChatsOutline,
  Icon28ChevronDownOutline,
  Icon28SendOutline,
} from '@vkontakte/icons'
import { Avatar, Link } from '@vkontakte/vkui'

import request from '../../../hooks/useHttp'
import useSound from 'use-sound';
import $ from 'jquery'

const GameMessanger = ({ id, fetchedUser, messages }) => {
  const [messageBlock, setMessageBlock] = useState(null)
  const [activeMessage, setActiveMessage] = useState(1)
  const [first, setFirst] = useState(true)
  const [thisMessages, setThisMessages] = useState([{_id: 0}])
  const [playGo] = useSound('https://app.footballcoin.ru/sound/message.mp3', {volume: 0.3});

  useEffect(() => {
    if (!messages) return
    if (messages[messages.length - 1]._id != thisMessages[thisMessages.length -1]._id) {
      setThisMessages(messages)
      setMessageBlock(null)
      if (first) return setFirst(false)
      playGo()
    }
  }, [messages])

  const sendMessage = async () => {
    let text = document.getElementById('game-message-input').value
    if (text.length < 1) return
    document.getElementById('game-message-input').value = ''
    const req = await request('new_game_message', 'POST', {
      url: document.location.search,
      message: text,
      id: Math.round(Math.random() * 10000000),
      ava: fetchedUser.photo_200 || null,
      mes: id,
    })
    lastMessageScroll()
  }

  const lastMessageScroll = async () => {}

  useEffect(() => {
    if (!messages) return
    if (activeMessage === 1) {
      setMessageBlock(
        <div className="messanger-mini">
          <div className="last-message">
            <div className="last-message-block">
              <Avatar
                src={
                  messages.length > 0 ? messages[messages.length - 1].ava : null
                }
                size={36}
              />
              <div className="last-message-block-text">
                <div className="last-message-block-text-body" >
                  <div className="last-message-block-tag">
                    {messages.length > 0
                      ? messages[messages.length - 1].tag
                      : null}
                  </div>
                  <div className="last-message-block-mes">
                    {messages.length > 0
                      ? messages[messages.length - 1].message
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="active-mini-chat" onClick={() => {
                setActiveMessage(2)
                lastMessageScroll()
              }}>
            <Icon28ChatsOutline />
          </div>
        </div>
      )

    } else {
      let mes = []
      for (let i = 0; i < messages.length; i++) {
        let clan = ''
        messages[i].clan ? (clan = `[${messages[i].clan}]`) : null
        mes.push(
          <div className="message-block" key={i}>
            <Link href={`https://vk.com/id${messages[i].id}`} target="_blank">
              <Avatar
                size={36}
                src={messages[i].ava}
                alt="Remy Sharp"
                className="message-photo"
              />
            </Link>
            <div className="message-block-head">
              <div className="message-tag">{`${messages[i].tag} ${clan}`}</div>
              <div className="message-text">{messages[i].message}</div>
            </div>
          </div>
        )
      }

      setMessageBlock(
        <div className="game-messanger" id="game-messanger">
          <div className="geme-messages-full">{mes}</div>
        </div>
      )
    }
  }, [thisMessages, activeMessage])


  useEffect(() => {
      $("#game-messanger").scrollTop(9999);
  }, [messageBlock])

  return <div>
    {messageBlock}
    {activeMessage != 1 ?
    <div className="game-messanger-bottom">
            <div
              onClick={() => {
                setActiveMessage(1)
                setFirst(true)
              }}
            >
              <Icon28ChevronDownOutline className="close-chat-game-button" />
            </div>
            <input id="game-message-input" maxLength="40" />
            <div onClick={sendMessage}>
              <Icon28SendOutline />
            </div>
          </div> : null}
  </div>
}

export default GameMessanger
