import React, { useState, useEffect, useRef } from 'react'
import {
    Panel,
    PanelHeader,
    PanelHeaderButton,
    Spinner
  } from '@vkontakte/vkui'
  import { Icon28ChevronBack } from '@vkontakte/icons'
  import { Icon56RecentOutline } from '@vkontakte/icons';

import request from '../../../hooks/useHttp'

import './PenaltyPvp.css'
import PenaltyPvpModal from './PenaltyPvpModal.jsx';
import PenaltyPvpGames from './PenaltyPvpGames';


const PenaltyPvp = (props) => {
    const interval = useRef(null)
    const [games, setGames] = useState(<Spinner/>)
    const [modal, setModal] = useState(null)

    const closeModal = () => {
      setModal(null)
    }

    const newGame = () => {
        if (modal) return
        setModal(<PenaltyPvpModal closeModal={closeModal}/>)
    }

    const startGame = (x) => {
      console.log(x);
    }

    useEffect(() => {
        interval.current = setInterval(async () => {
            const req = await request('get_penalty_pvp_games', 'POST', {
                url: document.location.search,
              })

              if (req.info.length > 0) {
                setGames(<PenaltyPvpGames games={req.info} startGame={startGame}/>)
              } else {
                setGames(
                    <div className='penalty_pvp-games-list-main'>
                        <div className='penalty_pvp-games-list'>
                            <Icon56RecentOutline fill='white' />
                            <p>В данный момент нет доступных комант, создайте игру первым</p>
                    </div>
                    </div>
                )
              }
        }, 500);

        return () => {
            clearInterval(interval.current)
        }
    }, [])

    useEffect(() => {
    }, [games, modal]);

    return (
        <Panel id={props.id}>
            <PanelHeader
            left={
              <PanelHeaderButton
                onClick={() => {
                  props.goMenu('main-menu')
                }}
              >
                <Icon28ChevronBack fill="white" />
              </PanelHeaderButton>
            }
          >
            Пенальти PVP
          </PanelHeader>
            {games}
            {modal}
          <div className='penalty_pvp-games-bottom_button-menu'>
              <div className='penalty_pvp-games-bottom_button' onClick={newGame}>
              Создать игру
              </div>
          </div>
        </Panel>
    )
}

export default PenaltyPvp