import React, { useState, useEffect, useRef } from 'react'

import {
  Panel,
  PanelHeader,
  PanelHeaderButton,
  HorizontalScroll,
  Link,
} from '@vkontakte/vkui'
import { Icon28ChevronBack } from '@vkontakte/icons'

import request from '../../../hooks/useHttp'
import GameMessanger from '../../items/gameMessanger/GameMessanger'
import GameUserProfile from '../../items/profile/GameUserProfile'
import PenaltyBetsBoard from './PenaltyBetsBoard'
import PenaltyBetBoard from './PenaltyBetBoard'

import './Penalty.css'

const Penalty = (props) => {
  const [game, setGamme] = useState({})
  const [history, setHistory] = useState(null)
  const interval = useRef(null)

  const setHistoryBlock = (game) => {
    if (!game.game.history) return

    let histx = []
    for (let i = game.game.history.length - 1; i >= 0; i--) {
      let x = game.game.history[i]
      let col = 'black'
      if (x.win < 1) {
        col = 'var(--win-red-results)'
      } else {
        col = 'var(--win-green-results)'
      }

      histx.push(
        <Link
          href={`https://vk.com/app7433551#${x.key}`}
          target="_blank"
          key={Math.random() * 1000000000}
        >
          <div className="history-game-ls-block" style={{ background: col }}>
            <p>{x.tap}</p>
          </div>
        </Link>
      )
    }
    return histx
  }

  useEffect(() => {
    interval.current = setInterval(async () => {
      const info = await request('get_penalty', 'POST', {
        url: document.location.search,
      })
      setGamme({
        result: {...info.game, time: 100},
        user: info.user,
        bets: info.bets,
        history: info.game.history,
        messages: info.messages,
      })
      setHistory(setHistoryBlock(info))
    }, 1000)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return (
    <Panel id={props.id}>
      <PanelHeader
        left={
          <PanelHeaderButton
            onClick={() => {
              props.goMenu('main-menu')
            }}
          >
            <Icon28ChevronBack fill="white" />
          </PanelHeaderButton>
        }
      >
        Пенальти
      </PanelHeader>

      <GameUserProfile game={game} fetchedUser={props.fetchedUser} />

      <div size="m" mode="shadow" className="football-result-card">
        <div className="football-game-text">История игр</div>
        <HorizontalScroll>
          <div style={{ display: 'flex' }}>{history}</div>
        </HorizontalScroll>
      </div>
      <PenaltyBetBoard game={game} />
      <PenaltyBetsBoard game={game} />

      <GameMessanger
        id="penalty-messanger"
        fetchedUser={props.fetchedUser}
        messages={game.messages}
      />
    </Panel>
  )
}

export default Penalty
