import React, { useState, useEffect } from 'react'

import { Cell, Avatar, Link } from '@vkontakte/vkui'
import './GameFootballBetsBoard.css'

import bridge from '@vkontakte/vk-bridge'
import format from '../../../hooks/formatCoins'

const GameFootballBetsBoard = ({ game }) => {
  const [bets, setBets] = useState([])
  const [avatar, setAvatar] = useState([])
  const [usersPhotos, setUsersPhotos] = useState({ response: [] })
  const [yourBets, setYourBets] = useState([])

  const setBetsInfo = () => {
    let colors = [
      'var(--win-blue-results)',
      'var(--win-red-results)',
      'var(--win-green-results)',
      'var(--win-score-results)',
    ]
    if (!game.bets) return
    let hist = []
    let yourhist = []

    const start = async () => {
      let ids = ''
      let idsx = []
      game.bets.map((x) => {
        ids += `${x.id}, `
        idsx.push(x.id)
      })

      if (avatar.length < idsx.length) {
        const token = await bridge.send('VKWebAppGetAuthToken', {
          app_id: 7599871,
          scope: '',
        })

        const photos = await bridge.sendPromise('VKWebAppCallAPIMethod', {
          method: 'users.get',
          request_id: '324nnefj',

          params: {
            v: '5.95',
            user_ids: ids,
            fields: 'photo_200',
            access_token: token.access_token,
          },
        })
        await setAvatar(idsx)
        await setUsersPhotos(photos)
      }

      game.bets.sort((a, b) => {
        return a.money - b.money
      })

      for (let i = game.bets.length - 1; i >= 0; i--) {
        let user = usersPhotos.response.find((x) => x.id === game.bets[i].id)
        if (!user) return
        let user_win = ''

        if (game.bets[i].bet === 1) {
          user_win = 'Синие'
        }
        if (game.bets[i].bet === 2) {
          user_win = 'Красные'
        }
        if (game.bets[i].bet === 3) {
          user_win = 'Ничья'
        }
        if (game.bets[i].bet === 4) {
          user_win = user_win = `${+game.bets[i].goal1} : ${+game.bets[i]
            .goal2}`
        }

        let moneyxx = (
          <p
            style={{
              margin: 0,
              color: 'white',
              fontWeight: 500,
            }}
          >
            {format((game.bets[i].money * 1).toFixed(0))}
          </p>
        )

        if (game.bets[i].win && +game.result.time < 0) {
          moneyxx = (
            <p
              style={{
                margin: 0,
                color: 'var(--win-green-results)',
                fontWeight: 500,
              }}
            >{`+${format(
              (+game.bets[i].money * +game.bets[i].win).toFixed(0)
            )}`}</p>
          )
        }

        if (!game.bets[i].win && +game.result.time < 0) {
          moneyxx = (
            <p
              style={{
                margin: 0,
                color: 'var(--win-red-results)',
                fontWeight: 500,
              }}
            >{`-${format((+game.bets[i].money * 1).toFixed(0))}`}</p>
          )
        }

        if (game.bets[i].id === game.user.id) {
          yourhist.push(
            <div
              className="my-bets-fc"
              key={i}
              style={{
                fontSize: '3vw',
              }}
            >
              {moneyxx}
              <p
                className="my-bets-fc-sum"
                style={{
                  background: colors[game.bets[i].bet - 1],
                }}
              >
                {user_win}
              </p>
            </div>
          )
        }

        hist.push(
          <Link
            href={`https://vk.com/id${game.bets[i].id}`}
            key={i}
            target="_blank"
            className="link-item-bets"
          >
            <div className="bets-history">
              <Cell
                className="bets-history-flex"
                before={
                  user.photo_200 ? (
                    <Avatar src={user.photo_200} size={36} />
                  ) : null
                }
                description={<div style={{ color: 'white' }}>{moneyxx}</div>}
              >
                <div className="bet-user-tag">{game.bets[i].tag}</div>
              </Cell>
              <div
                className="bets-history-res"
                style={{
                  background: colors[game.bets[i].bet - 1],
                }}
              >
                {user_win}
              </div>
            </div>
          </Link>
        )
      }
      setYourBets(yourhist)
      setBets(hist)
    }
    start()
  }

  useEffect(() => {
    if (game.result) {
      if (+game.result.time === 60) {
        setAvatar([])
      }
    }

    setBetsInfo()
  }, [game])

  return (
    <div>

      {
          yourBets.length ? <div className="my-bets-grid-x">
          <div className="my-bets-grid-x-header">Ваши ставки:</div>
          <div className="my-bets-grid">{yourBets}</div>
        </div> : null
      }
      
      {bets.length ? <div className="my-bets-grid-x-header">Ставки игроков:</div> : null}
      
      <div
        style={{ paddingBottom: 'calc(var(--safe-area-inset-bottom) + 55px)' }}
      >
        {bets}
        <div className="game-hash">
          🔐 {game.result ? game.result.hash : null}
        </div>
      </div>
    </div>
  )
}

export default GameFootballBetsBoard
