import React, { useEffect, useState } from 'react'

import { Icon56RecentOutline } from '@vkontakte/icons'
import useSound from 'use-sound';

import './GameLuckySpinBoard.css'

const GameLuckySpinBoard = (props) => {
  const [res, setRes] = useState(null)
  const [playGo] = useSound('https://app.footballcoin.ru/sound/roulette.mp3', {volume: 0.3});

  useEffect(() => {
    if (props.game.result) {
      setRes(
        <div className="game-luckyspin-board-timer">
          <div>{props.game.result.time}</div>
        </div>
      )
      if (+props.game.result.time === 60) {
        setRes(
          <div>
            <Icon56RecentOutline style={{ margin: '0 auto' }} />
            <div>Ожидание ставок!</div>
          </div>
        )
      }

      if (props.game.result.time === 0) {
          playGo()
      }

      if (+props.game.result.time < 0) {
        setRes(
          <div className="ls-result-board">
            <div
              className="game-luckyspin-board-result-img"
              style={{
                transform: `rotate(${
                  props.game.result.id === 0
                    ? 5400 - 360
                    : 5400 + props.game.result.id * 9.729
                }deg)`,
              }}
            ></div>
            <div
              className="game-luckyspin-board-result"
              style={{ background: props.game.result.color }}
            >
              <div>{props.game.result.number}</div>
            </div>
          </div>
        )
      }
    }
  }, [props.game])

  return (
    <div className="game-luckyspin-board-fon">
      <div className="game-luckyspin-board">{res}</div>
    </div>
  )
}

export default GameLuckySpinBoard
