import React, { useState, useEffect } from 'react'

import './GameLuckySpinBet.css'

import { Snackbar } from '@vkontakte/vkui'
import { Icon24DismissSubstract, Icon28DoneOutline } from '@vkontakte/icons'

import disImage from '../../../img/not-ok.png'

import request from '../../../hooks/useHttp'
import format from '../../../hooks/formatCoins'

const betsText = [
  'ЧЕТНОЕ',
  'НЕЧЕТНОЕ',
  'КРАСНОЕ',
  'ЧЕРНОЕ',
  'ПРОМЕЖУТОК',
  'ЧИСЛО',
]

let bleckx = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33,35]
let redx = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36]

const GameLuckySpinBet = ({ game }) => {
  const [scoreBoard, setScoreBoard] = useState(null)
  const [bet, setBet] = useState(null)
  const [num, setNum] = useState(0)
  const [imputValue, setImputValue] = useState('10000')
  const [snackBar, setSnackBar] = useState(null)

  const letNumberBorder = (s) => {
    if (s === num) return '0.3'
  }

  const goBetLs = async () => {
    try {
      const req = await request('new_bet_ls', 'POST', {
        url: document.location.search,
        bet: bet,
        result: num,
        money: +document
          .getElementById('football-bet')
          .value.replace(/\s/g, ''),
      })
    } catch (e) {
      setSnackBar(
        <Snackbar
          onClose={() => setSnackBar(null)}
          before={
            <img
              src={disImage}
              alt="ok"
              width="24"
              height="24"
              align="center"
            />
          }
        >
          <div style={{ color: 'white', fontWeight: 500 }}>{e.message}</div>
        </Snackbar>
      )
    }
    setBet(null)
  }

  const newBet = (i) => {
    let x = document.getElementById('football-bet')
    let s = +x.value.replace(/\s/g, '')
    s += i
    x.value = s.toFixed(0)
    setImputValue(s)
  }

  const newBets = (i) => {
    let x = document.getElementById('football-bet')
    let s = +x.value.replace(/\s/g, '')

    if (i === 1) {
      s = s * 2
    } else {
      s = s / 2
    }

    x.value = s.toFixed(0)
    setImputValue(s)
  }

  useEffect(() => {
    let bar = null
    if (bet != null) {
      let goalBoard = null

      if (bet === 6) {
        let nums = []
        let col = ''
        for (let i = 0; i <= 36; i++) {
          if (redx.find((x) => x === i)) {
            col = 'red'
          }
          if (bleckx.find((x) => x === i)) {
            col = 'black'
          }
          if (i === 0) {
            col = 'green'
          }
          nums.push(
            <div
              className="ls-numbersx"
              style={{
                background: col,
                opacity: letNumberBorder(i),
              }}
              onClick={() => setNum(i)}
            >
              {i}
            </div>
          )
        }
        goalBoard = nums
      }

      bar = (
        <div className="score-board-bet-fc">
          <div className="score-board-bet-fc-header">
            <div>СТАВКА НА {betsText[bet - 1]}</div>
            <Icon24DismissSubstract
              fill="red"
              onClick={() => {
                setScoreBoard(null)
                setBet(null)
              }}
            />
          </div>
          <div className="lucky-spin-numbers-board">{goalBoard}</div>
          <div className="bet-board-fc-menu">
            <div className="bet-fc-menu-ul">
              <div
                onClick={() => {
                  newBet(100000)
                }}
              >
                +100К
              </div>
              <div
                onClick={() => {
                  newBet(1000000)
                }}
              >
                +1КК
              </div>

              <div
                onClick={() => {
                  newBet(10000000)
                }}
              >
                +10КК
              </div>
              <div
                onClick={() => {
                  newBet(-1000000)
                }}
              >
                -1КК
              </div>
            </div>
            <div className="bet-fc-input-ul">
              <input
                maxLength="10"
                id="football-bet"
                value={format(imputValue)}
                onChange={() => {
                  let num = document.getElementById('football-bet')
                  setImputValue(
                    +num.value.replace(/[^\d]/g, '').replace(/\s/g, '')
                  )
                }}
              />
              <div
                onClick={() => {
                  newBets(1)
                }}
              >
                x2
              </div>
              <div
                onClick={() => {
                  newBets(2)
                }}
              >
                /2
              </div>
            </div>
          </div>

          <div
            className="bet-fs-button"
            onClick={() => {
              goBetLs()
              setScoreBoard(null)
            }}
          >
            <Icon28DoneOutline style={{ marginRight: 5 }} />
            <div>ПОСТАВИТЬ</div>
          </div>
        </div>
      )
    }
    setScoreBoard(bar)
  }, [bet, num, imputValue])

  return (
    <div>
      <div className="luckyspin-bets-board">
        <div className="luckyspin-bets-board-color">
          <div
            onClick={() => {
              setBet(3)
            }}
          >
            Красное
          </div>
          <div
            onClick={() => {
              setBet(4)
            }}
          >
            Черное
          </div>
        </div>
        <div className="luckyspin-bets-board-numbers">
          <div
            onClick={() => {
              setBet(5)
              setNum(1)
            }}
          >
            1-12
          </div>
          <div
            onClick={() => {
              setBet(5)
              setNum(13)
            }}
          >
            13-24
          </div>
          <div
            onClick={() => {
              setBet(5)
              setNum(25)
            }}
          >
            25-36
          </div>
        </div>
        <div className="luckyspin-bets-board-numbers">
          <div
            onClick={() => {
              setBet(1)
            }}
          >
            Четное
          </div>
          <div
            onClick={() => {
              setBet(6)
            }}
          >
            На число
          </div>
          <div
            onClick={() => {
              setBet(2)
            }}
          >
            Нечетное
          </div>
        </div>
      </div>
      {scoreBoard}
      {snackBar}
    </div>
  )
}

export default GameLuckySpinBet
