import React from 'react'

import { Panel, PanelHeader } from '@vkontakte/vkui'
import { Icon56InfoOutline } from '@vkontakte/icons'
import '../css/ErrorPanel.css'

const WarningPanel = ({ id, goMenu }) => {
  return (
    <Panel id={id}>
      <PanelHeader>Ошибка</PanelHeader>
      <div className="error_information">
        <p>ОШИБКА СЕТИ</p>
        <Icon56InfoOutline fill="red" className="error-icon" />
        <div>Вы отключены от сервера! Попробуйте переподключиться!</div>
      </div>
      <div
        className="error_button"
        onClick={() => {
          goMenu('main-menu')
        }}
      >
        Попробовать снова
      </div>
    </Panel>
  )
}

export default WarningPanel
