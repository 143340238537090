import React, { useEffect, useState } from 'react'
import ball from './img/ball.png'
import { Snackbar } from '@vkontakte/vkui'
import disImage from '../../../img/not-ok.png'
import request from '../../../hooks/useHttp'
import format from '../../../hooks/formatCoins'
import PenaltyBetBoardButtons from './PenaltyBetBoardButtons'
import './PenaltyBetBoard.css'
const animations = ["ballArrow1", "ballArrow2", "ballArrow3"]
import useSound from 'use-sound';

const PeanltyBetBoard = (props) => {
  const [body, setBody] = useState(null)
  const [activeSave, setActiveSave] = useState([null, null, null])
  const [bet, setBet] = useState(null)
  const [imputValue, setImputValue] = useState('10000')
  const [snackBar, setSnackBar] = useState(null)
  const [playGo] = useSound('https://app.footballcoin.ru/sound/ball.mp3');

  const goPenaltyGame = async () => {
    if (!bet) return
    try {
      const req = await request('get_penalty_result', 'POST', {
        url: document.location.search,
        result: bet,
        bet: +document.getElementById('football-bet').value.replace(/\s/g, ''),
      })

      console.log(req);

      setBody(
        <div className="penalty-bet-board">
          <div className="save-button-penalty-block">
            <div
              style={{
                background: `${activeSave[0] || 'rgba(0, 0, 0, 0.300)'}`,
              }}
              className="save-button-penalty"
              onClick={() => {
                setActiveSave(["var(--penalty-result5)", null, null])
                setBet(1)
              }}
            >
              1
            </div>
            <div
              style={{
                background: `${activeSave[1] || 'rgba(0, 0, 0, 0.300)'}`,
              }}
              className="save-button-penalty"
              onClick={() => {
                setActiveSave([null, "var(--penalty-result5)", null])
                setBet(2)
              }}
            >
              2
            </div>
            <div
              style={{
                background: `${activeSave[2] || 'rgba(0, 0, 0, 0.300)'}`,
              }}
              className="save-button-penalty"
              onClick={() => {
                setActiveSave([null, null, "var(--penalty-result5)"])
                setBet(3)
              }}
            >
              3
            </div>
          </div>

          <img className="penalty-ball" src={ball} alt="o" 
            style={{animation: `${animations[req.user_penalty.result-1]} 1000ms cubic-bezier(0.5, 0.5, 0.4, 1.2)`}}/>
            <div className='penalty-game-result-max' style={{ color: req.win < 0 ? 'red' : 'green' }}>{req.win < 0 ? format(req.win) : "+" + format(req.win * 2.8)}</div>
        </div>
      )
      
      setTimeout(() => {
        setActiveSave([...activeSave])
      }, 1000)
      playGo()
    } catch (e) {
      setSnackBar(
        <Snackbar
          onClose={() => setSnackBar(null)}
          before={
            <img
              src={disImage}
              alt="ok"
              width="24"
              height="24"
              align="center"
            />
          }
        >
          <div style={{ color: 'white', fontWeight: 500 }}>{e.message}</div>
        </Snackbar>
      )
    }
  }

  const newBet = (i) => {
    let x = document.getElementById('football-bet')
    let s = +x.value.replace(/\s/g, '')
    s += i
    x.value = s.toFixed(0)
    setImputValue(s)
  }

  const newBets = (i) => {
    let x = document.getElementById('football-bet')
    let s = +x.value.replace(/\s/g, '')

    if (i === 1) {
      s = s * 2
    } else {
      s = s / 2
    }

    x.value = s.toFixed(0)
    setImputValue(s)
  }

  useEffect(() => {
    setBody(
      <div className="penalty-bet-board">
        <div className="save-button-penalty-block">
          <div
            style={{
              background: `${activeSave[0] || 'rgba(0, 0, 0, 0.300)'}`,
            }}
            className="save-button-penalty"
            onClick={() => {
              setActiveSave(["var(--penalty-result5)", null, null])
              setBet(1)
            }}
          >
            1
          </div>
          <div
            style={{
              background: `${activeSave[1] || 'rgba(0, 0, 0, 0.300)'}`,
            }}
            className="save-button-penalty"
            onClick={() => {
              setActiveSave([null,"var(--penalty-result5)",null])
              setBet(2)
            }}
          >
            2
          </div>
          <div
            style={{
              background: `${activeSave[2] || 'rgba(0, 0, 0, 0.300)'}`,
            }}
            className="save-button-penalty"
            onClick={() => {
              setActiveSave([null, null, "var(--penalty-result5)"])
              setBet(3)
            }}
          >
            3
          </div>
        </div>
        <img className="penalty-ball" src={ball} alt="o" width="20px" />
      </div>
    )
  }, [activeSave])

  return (
    <div>
      <div className="penalty-bet-board-fon">{body}</div>
      <PenaltyBetBoardButtons
        newBet={newBet}
        goPenaltyGame={goPenaltyGame}
        newBets={newBets}
        imputValue={imputValue}
        setImputValue={setImputValue}
        user={props.game.user}
      />

      {snackBar}
    </div>
  )
}

export default PeanltyBetBoard
