import React from 'react'

import { Snackbar } from '@vkontakte/vkui'

import bridge from '@vkontakte/vk-bridge'

import request from '../../../hooks/useHttp'
import format from '../../../hooks/formatCoins'
import okImage from '../../../img/ok.png'
import disImage from '../../../img/not-ok.png'


const getBonus = async (setSnackBar, url) => {
  
  bridge 
    .sendPromise('VKWebAppShowNativeAds', { ad_format: 'reward' })
    .then(async (data) => {
      try {
        let req = await request('get_free_case', 'POST', {
          url,
        })

        setSnackBar(
          <Snackbar
            onClose={() => setSnackBar(null)}
            before={
              <img
                src={okImage}
                alt="ok"
                width="24"
                height="24"
                align="center"
              />
            }
          >
            <div style={{ color: 'white', fontWeight: 500 }}>
              Вы получили {format((req.prize * 1).toFixed(0))} VK Coin!
            </div>
          </Snackbar>
        )
      } catch (e) {
        setSnackBar(
          <Snackbar
            onClose={() => setSnackBar(null)}
            before={
              <img
                src={disImage}
                alt="ok"
                width="24"
                height="24"
                align="center"
              />
            }
          >
            <div style={{ color: 'white', fontWeight: 500 }}>{e.message}</div>
          </Snackbar>
        )
      }
    })
    .catch((error) => {
      setSnackBar(
        <Snackbar
          onClose={() => setSnackBar(null)}
          before={
            <img
              src={disImage}
              alt="ok"
              width="24"
              height="24"
              align="center"
            />
          }
        >
          <div style={{ color: 'white', fontWeight: 500 }}>{error.message}</div>
        </Snackbar>
      )
    })
}

export default getBonus
