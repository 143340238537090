import React, { useState, useEffect } from 'react'
import { Group, Cell, Avatar } from '@vkontakte/vkui'

import coin from '../../../css/coin.svg'
import giftbox from '../../../css/giftbox.svg'

import format from '../../../hooks/formatCoins'

const GameUserProfile = (props) => {
  const [game, setGame] = useState(props)

  useEffect(() => {
    if (props.game.result) {
      if (props.game.result.time > 0) {
        setGame(props)
      }
    }
  }, [props.game])
  return (
    <Group title="User Data" className="user-game-data">
      <Cell
        before={
          game.fetchedUser.photo_200 ? (
            <Avatar src={game.fetchedUser.photo_200} size={36} />
          ) : null
        }
        description={
          <div className="user-balance-footbal-item">
            {format(
              game.game.user
                ? (game.game.user.balanceBonus * 1).toFixed(0)
                : null
            )}{' '}
            <img src={giftbox} alt="coin" align="center" width="14px" />
          </div>
        }
      >
        <div className="user-balancebonus-footbal-item">
          {format(
            game.game.user ? (game.game.user.balance * 1).toFixed(0) : null
          )}{' '}
          <img src={coin} alt="coin" align="center" width="16px" />
        </div>
      </Cell>
    </Group>
  )
}

export default GameUserProfile
