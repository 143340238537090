import React, { useEffect, useRef, useState } from "react";
import format from '../../../hooks/formatCoins'
import { Checkbox } from '@vkontakte/vkui'
import request from '../../../hooks/useHttp'
import { Snackbar } from '@vkontakte/vkui'
import disImage from '../../../img/not-ok.png'
import { Icon20CancelCircleFillRed } from '@vkontakte/icons';

const PenaltyPvpModal = ({ closeModal }) => {
    const [imputValue, setImputValue] = useState(0)
    const [pass, setPass] = useState('')
    const [password, setPassword] = useState(null)
    const [check, setCheck] = useState(null)
    const [snackBar, setSnackBar] = useState(null)

    
  const newBets = (i) => {
    let x = document.getElementById('football-bet')
    let s = +x.value.replace(/\s/g, '')

    if (i === 1) {
      s = s * 2
    } else {
      s = s / 2
    }

    x.value = s.toFixed(0)
    setImputValue(s)
  }

  const getPass = () => {
      if (pass.length > 0) {
        setPassword(null)
        setPass('')
        setCheck(false)
      } else {
        setPass('1234')
        setCheck(true)
      }
  }

  const newPass = () => {
    setPass(document.getElementById('password-penalty-pvp').value)
    console.log(document.getElementById('password-penalty-pvp').value);
  }

  const startGame = async () => {
    const req = await request('new_penalty_pvp_game', 'POST', {
      url: document.location.search,
      bet: imputValue,
      password: pass
    })

    if (req.error) {
      setSnackBar(
        <Snackbar
          onClose={() => setSnackBar(null)}
          before={
            <img
              src={disImage}
              alt="ok"
              width="24"
              height="24"
              align="center"
            />
          }
        >
          <div style={{ color: 'white', fontWeight: 500 }}>{req.error}</div>
        </Snackbar>
      )
    } else {

    }
  }

  useEffect(() => {
    if (pass.length > 0) {
        setPassword(
            <div className="bet-fc-input-ul">
                <input
                    maxLength="10"
                    id="password-penalty-pvp"
                    onChange={newPass} />
            </div>
            )
    } 
  }, [check])


    return (
        <div className="penalty-pvp-modal">
            <div className="penalty-pvp-modal-window">
                <div className="penalty-pvp-modal-window-header">
                  <div>Новая игра</div>
                  <Icon20CancelCircleFillRed onClick={closeModal}/></div>
                <div className="bet-fc-input-ul">
                    <input
                        maxLength="10"
                        id="football-bet"
                        value={format(imputValue)}
                        onChange={() => {
                          let num = document.getElementById('football-bet')
                          setImputValue(+num.value.replace(/[^\d]/g, '').replace(/\s/g, ''))
                        }} />
                        <div style={{ backgroundColor: 'rgba(0, 178, 191, 0.8)'}} onClick={() => {newBets(1)}}> x2</div>
                        <div style={{ backgroundColor: 'rgba(0, 178, 191, 0.8)'}} onClick={() => {newBets(2)}}>/2</div>
                </div>
                <div>
                    <Checkbox onChange={getPass} ><div style={{color: 'white'}}>Установить пароль</div></Checkbox>
                    {password}
                </div>
                <div className="penalty-pvp-modal-window-button" onClick={startGame}>Создать игру</div>
            </div>
            {snackBar}
        </div>
    )
}

export default PenaltyPvpModal