import React, { useRef, useEffect, useState } from 'react'

import { Panel, PanelHeader, PanelHeaderButton } from '@vkontakte/vkui'
import { Icon28ChevronBack } from '@vkontakte/icons'
import bridge from '@vkontakte/vk-bridge'
import $ from 'jquery'
import md5 from 'md5'
import request from '../../../hooks/useHttp'

import './BouncingBall.css'
import fon from './img/fon.png'
import fon2 from './img/fon2.png'
import ballImg from './img/ball.png'
import blockimg from './img/block.png'
import blockimg2 from './img/block2.png'
import blockimg3 from './img/block3.jpg'
import blockimg4 from './img/block4.png'
import fonAudioFile from './img/fonAudio.mp3'
import bouncAudioFile from './img/bouncAudio.mp3'
import gameOverFile from './img/gameOver.mp3'

var fonAudio = new Audio();
fonAudio.src = fonAudioFile
fonAudio.loop = true;
var bouncAudio = new Audio();
bouncAudio.src = bouncAudioFile
var gameOver = new Audio();
gameOver.src = gameOverFile


const BouncingBall = ({id, goMenu, fetchedUser, user}) => {
  const canvasRef = useRef()
  const score = useRef(0)
  const [scorexs, setScorex] = useState(0)
  const [startGame, setGame] = useState(false)
  const [menu, setMenu] = useState(null)
  const [speedRunScore, setSpeedRunScore] = useState(0)
  const interval = useRef(0)
  const hash = useRef(fetchedUser.id)
  const [record, setRecord ]= useState(0)

  useEffect( () => {
    const start = async () => {
      fonAudio.play()
      let user = await request('get_user', 'POST', {
        url: document.location.search,
      })
      setRecord(user.user.bouncingball ? user.user.bouncingball : 0)
    }
    start()
    return () => {
      fonAudio.pause()
    }
  }, [])

  useEffect(() => {
const start = async () => {
    let sc = score.current
    let hashxx = hash.current
    let hashx = await md5(`${fetchedUser.id}_${sc}_${hashxx}`)
    hash.current = hashx
    let req = await request('bouncing-ball-set-result', 'POST', {
      url: document.location.search,
      hash: hashx,
      score: sc
    })
}
    interval.current = setInterval( () => {
      start()
    }, 10000)

    const getTop = async () => {
      let req = await request('bouncing-ball-get-top', 'POST', {
        url: document.location.search,
      })

      let ids = ''
      req.top.map((x) => {
        ids += `${x.id}, `
      })

      const token = await bridge.send('VKWebAppGetAuthToken', {
        app_id: 7599871,
        scope: '',
      })

      const photos = await bridge.sendPromise('VKWebAppCallAPIMethod', {
        method: 'users.get',
        request_id: '324nnefj',

        params: {
          v: '5.95',
          user_ids: ids,
          fields: 'photo_200',
          access_token: token.access_token,
        },
      })

      req.users_photo = photos.response
      let Items = []

      for (let i = 0; i < req.top.length; i++) {
          let user_ava = req.users_photo.find((x) => x.id === req.top[i].id)
          if (!user_ava) {
            user_ava = { photo_200: null }
          }
          Items.push(
            <Link
              href={`https://vk.com/id${req.top[i].id}`}
              key={Math.random() * 100000}
              target="_blank"
            >
              <div className="top-users">
                <div key={Math.random() * 100000} className="top-num">
                  <h5>{i + 1}</h5>
                </div>
                <Cell
                  description={
                    <div className="top-user-win">{`${format(
                      req.top[i].score
                    )} VK Coin`}</div>
                  }
                  key={Math.random() * 100000}
                  before={<Avatar src={user_ava.photo_200} size={36} />}
                >
                  <div className="top-user-tag">
                    {user_ava.first_name}
                  </div>
                </Cell>
              </div>
            </Link>
          )
      }

      setMenu(Items)
    }

    const startMenu = () => {
      setMenu(<div className='bouncing-ball-menu'>
        <div className='bouncing-ball-button-menu' onClick={() => {
          setGame(true)
          setMenu(null)
        }}>Играть</div>
        <div className='bouncing-ball-button-menu' onClick={() => {
          setGame(false)
          getTop()
        }}>Топ игроков</div>
        <div className='bouncing-ball-button-menu' 
        style={{background: 'red'}} onClick={() => {
          goMenu('main-menu')
        }}>Выход</div>
      </div>)
    }
    if (!startGame) {
      startMenu()
    }

    let scorex = 0
    let stop = false
    let speedRun = 0
    let tim = 0

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const ctx2 = canvas.getContext('2d')
    const blocks = [{id: canvas.width * 3, block: 0} ]
    let animation = null

    let x = 0
    let y = canvas.width * 2
    let a = 0

    const bg = new Image()
    const bg2 = new Image()
    const ball = new Image()
    const block = new Image()
    const block2 = new Image()
    const block3 = new Image()
    const block4 = new Image()
    

    bg.src = fon
    bg2.src = fon2
    ball.src = ballImg
    block.src = blockimg
    block2.src = blockimg2
    block3.src = blockimg3
    block4.src = blockimg4

    let blockxxx = [block, block2, block3, block4, block]

    const startPosition = (canvas.height - canvas.height / 5.555) - canvas.width / 10  + bg2.height / 5
    let ballPosition = startPosition
    let speed = 0
    let bounc = false
    
    $("#bouncing-ball-canvas").on( "click", () => {
      if (!bounc) {
        bouncAudio.play()
      }
      bounc = true
    })

    ctx.drawImage(bg, x, 0, canvas.width * 2, canvas.height)

    const draw = async () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx2.clearRect(0, 0, canvas.width, canvas.height)

      x -= 5 + speedRun
      y -= 5 + speedRun
      a += 5 + speedRun
      tim += 1
      

      if (x < -canvas.width * 3) {
        x = y + canvas.width * 2
      }
      if (y < -canvas.width * 3) {
        y = x + canvas.width * 2
      }

      if (bounc === true) {
        if (ballPosition < startPosition + canvas.width / 15) {
          speed -= 0.18
          ballPosition -= 5 + speed
        } else {
          speed += 0.18
          ballPosition += 5 + speed
        }
        if (ballPosition > startPosition ) {
          bounc = false
          speed = 0
          ballPosition = startPosition
        }
      }
      
      if (blocks[blocks.length - 1].id < canvas.width) {
        let xx = Math.round(Math.random() * 2)
        let xxx = Math.round(Math.random() * 4)
        if (Math.round(Math.random() * 2) === 2) {
          blocks.push({
            id: Math.round((Math.random() * canvas.width) + blocks[blocks.length - 1].id + canvas.width / 3 + canvas.width * speedRun / 10),
            block: xxx
          })
          blocks.push({
            id: blocks[blocks.length - 1].id + canvas.width / 15,
            block: xxx
          })
          if (speedRun > 5 && xx === 2) {
            blocks.push({
              id: blocks[blocks.length - 1].id + canvas.width / 15,
              block: xxx
            })
          }
        } else {
          blocks.push({
            id: Math.round((Math.random() * canvas.width) + blocks[blocks.length - 1].id + canvas.width / 3 + canvas.width * speedRun / 10),
            block: xxx
          })
        }
       
      }

      if (startGame) {
      if (blocks.find(x => x.id < -100)) {
        blocks.splice(0, 1)
      }
        scorex += 1
        setScorex(scorex)
        if (record < scorex) {
          setRecord(scorex)
        }
        score.current = scorex

      if (tim % 200 === 0) {
        speedRun += 0.3
        setSpeedRunScore(speedRun)
      }
      } 
      
      ctx.drawImage(bg, x, 0, canvas.width * 2, canvas.height)
      ctx.drawImage(bg, y, 0, canvas.width * 2, canvas.height)

      ctx.drawImage(bg2, x, (canvas.height - canvas.height / 5.555) - (canvas.width / 10), canvas.width * 2, canvas.height / 4)
      ctx.drawImage(bg2, y, (canvas.height - canvas.height / 5.555) - (canvas.width / 10), canvas.width * 2, canvas.height / 4)
      if (startGame) {
      for (let i = 0; i < blocks.length; i++) {
        blocks[i].id -= 5 + speedRun
        ctx.drawImage(blockxxx[blocks[i].block], blocks[i].id, startPosition - canvas.width / 30,  canvas.width / 15,canvas.width / 15)
        if (blocks[i].id < canvas.width / 4 + canvas.width / 30
          &&  blocks[i].id > canvas.width / 4 - canvas.width / 10
          && ballPosition > startPosition - canvas.width / 30) {
            gameOver.play()
            stop = true
            clearInterval(interval.current)
            bridge.sendPromise('VKWebAppShowNativeAds', { ad_format: 'interstitial' })
        }
      }
      }

      ctx2.save();
      ctx2.translate(canvas.width / 4, ballPosition);
      ctx2.rotate(a*Math.PI/180);
      ctx2.drawImage(ball, -canvas.width / 30, -canvas.width / 30, canvas.width / 15,canvas.width / 15);
      ctx2.restore(); 

      animation = requestAnimationFrame(draw);
      
      if (stop) {
        cancelAnimationFrame(animation)
        startMenu()
        setGame(false)
      }
    
     }

    draw()

    return () => {
      $("#bouncing-ball-canvas").trigger( "click" )
      cancelAnimationFrame(animation)
      clearInterval(interval.current)
    }
  }, [startGame])

    return (
    <Panel id={id}>
      <PanelHeader
        left={
          <PanelHeaderButton
            onClick={() => {
              goMenu('main-menu')
            }}
          >
            <Icon28ChevronBack fill="white" />
          </PanelHeaderButton>
        }
      >
        Bouncing Ball
      </PanelHeader>
        {menu}
        <div className="bouncing-ball-score">Score: {scorexs} Speed: {speedRunScore.toFixed(1)} Record: {record}</div>
        <div className="bouncing-ball-canvas-block">
        <canvas ref={canvasRef} id='bouncing-ball-canvas' className="bouncing-ball-canvas" width={window.innerHeight * 1.25} height={window.innerHeight * 2}/>
        </div>
       
    </Panel>
)
}

export default BouncingBall