import React, { useState, useEffect } from 'react'

import {
  Panel,
  PanelHeader,
  Link,
  Cell,
  Avatar,
  Spinner,
} from '@vkontakte/vkui'

import bridge from '@vkontakte/vk-bridge'
import format from '../hooks/formatCoins'
import request from '../hooks/useHttp'

import '../css/Top.css'

const items = ['all', 'day', 'dayteams', 'teams']

const Top = (props) => {
  const [top, setTop] = useState('all')
  const [topUsers, setTopUsers] = useState(
    <Spinner size="medium" style={{ margin: '20px 0' }} />
  )
  const [background, setBackground] = useState(
    ['rgba(19, 0, 130, 0.5)'],
    null,
    null,
    null
  )

  const setSpinner = () => {
    setTopUsers(<Spinner size="medium" style={{ margin: '20px 0' }} />)
  }

  useEffect(() => {
    let item = items.indexOf(top)
    let itm = [null, null, null, null]
    itm[item] = 'rgba(19, 0, 130, 0.5)'
    setBackground(itm)
    const letTop = async () => {
      let req = await request('get_top', 'POST', {
        url: document.location.search,
        top,
      })

      let ids = ''
      req.top.map((x) => {
        ids += `${x.id}, `
      })

      const token = await bridge.send('VKWebAppGetAuthToken', {
        app_id: 7599871,
        scope: '',
      })

      const photos = await bridge.sendPromise('VKWebAppCallAPIMethod', {
        method: 'users.get',
        request_id: '324nnefj',

        params: {
          v: '5.95',
          user_ids: ids,
          fields: 'photo_200',
          access_token: token.access_token,
        },
      })

      req.users_photo = photos.response

      let Items = []

      let user = {}

      if (req.top.find((x) => x.header && x.id === props.fetchedUser.id)) {
        user = req.top.find((x) => x.header && x.id === props.fetchedUser.id)
      } else {
        user = {
          header: true,
          id: props.fetchedUser.id,
          tag: 'Вы не состоите в команде',
          top: -1,
          win: '0',
        }
      }

      Items.push(
        <Link
          href={`https://vk.com/id${user.id}`}
          key={Math.random() * 100000}
          target="_blank"
        >
          <div
            className="top-users"
            style={{ background: 'var(--top-user-item)' }}
          >
            <div key={Math.random() * 100000} className="top-num">
              <h5>{user.top + 1}</h5>
            </div>
            <Cell
              description={
                <div className="top-user-win">{`${format(
                  user.win
                )} VK Coin`}</div>
              }
              key={Math.random() * 100000}
              before={
                <Avatar
                  src={props.fetchedUser.photo_200}
                  size={36}
                  style={{ border: 'solid 2px green' }}
                />
              }
            >
              <div className="top-user-tag">{user.tag}</div>
            </Cell>
          </div>
        </Link>
      )

      for (let i = 0; i < 50; i++) {
        let clan = ''
        if (top === 'teams') {
          let clanUsers = 0
          req.top[i].users
            .filter((x) => x.active === true)
            .map((x) => {
              clanUsers++
            })
          clan = `[${clanUsers}/22]`
        }
        if (!req.top[i].header) {
          let user_ava = req.users_photo.find((x) => x.id === req.top[i].id)
          if (!user_ava) {
            user_ava = { photo_200: null }
          }
          Items.push(
            <Link
              href={`https://vk.com/id${req.top[i].id}`}
              key={Math.random() * 100000}
              target="_blank"
            >
              <div className="top-users">
                <div key={Math.random() * 100000} className="top-num">
                  <h5>{req.top[i].top + 1}</h5>
                </div>
                <Cell
                  description={
                    <div className="top-user-win">{`${format(
                      req.top[i].win
                    )} VK Coin`}</div>
                  }
                  key={Math.random() * 100000}
                  before={<Avatar src={user_ava.photo_200} size={36} />}
                >
                  <div className="top-user-tag">
                    {req.top[i].tag} {clan}
                  </div>
                </Cell>
              </div>
            </Link>
          )
        }
      }

      await setTopUsers(Items)
    }
    letTop()
  }, [top])

  return (
    <Panel id={props.id}>
      <PanelHeader>Топ</PanelHeader>
      <div className="top-menu">
        <div
          className="top-menu-items"
          style={{ background: background[0] }}
          onClick={() => {
            setTop('all')
            setSpinner()
          }}
        >
          Топ игроков
        </div>
        <div
          className="top-menu-items"
          style={{ background: background[1] }}
          onClick={() => {
            setTop('day')
            setSpinner()
          }}
        >
          За день
        </div>
        <div
          className="top-menu-items"
          style={{ background: background[2] }}
          onClick={() => {
            setTop('dayteams')
            setSpinner()
          }}
        >
          Топ команд
        </div>
        <div
          className="top-menu-items"
          style={{ background: background[3] }}
          onClick={() => {
            setTop('teams')
            setSpinner()
          }}
        >
          За неделю
        </div>
      </div>
      <div>{topUsers}</div>
    </Panel>
  )
}

export default Top
