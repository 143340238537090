import React, { useEffect, useState, useRef } from 'react'

import {
    Panel,
    PanelHeader,
    PanelHeaderButton,
    HorizontalScroll,
    Link,
  } from '@vkontakte/vkui'
  import { Icon28ChevronBack } from '@vkontakte/icons'
  
  import GameIceCubeBoard from './GameIceCubeBoard.jsx'
  import GameIceCubeBet from './GameIceCubeBet.jsx'
  import GameIceCubeBetsBoard from './GameIceCubeBetsBoard.jsx'
  import GameMessanger from '../../items/gameMessanger/GameMessanger'
  import GameUserProfile from '../../items/profile/GameUserProfile'
  
  import request from '../../../hooks/useHttp'

  
const IceCube = (props) => {
    const [game, setGamme] = useState({})
    const [history, setHistory] = useState(null)
    const interval = useRef(null)

    const setHistoryBlock = (game) => {
        if (!game.history) return
    
        let histx = []
        for (let i = game.history.length - 1; i >= 0; i--) {
          let x = game.history[i]
          let col = 'black'
          if (x.color === 'White') {
            col = 'var(--luckyball-white-win)'
          }
          if (x.color === 'Green') {
            col = 'var(--win-green-results)'
          }
    
          histx.push(
            <Link
              href={`https://vk.com/app7433551#${x.key}`}
              target="_blank"
              key={Math.random() * 1000000000}
            >
              <div className="history-game-ls-block" style={{ background: col }}>
                <p>{x.number}</p>
              </div>
            </Link>
          )
        }
        return histx
      }

  useEffect(() => {
    interval.current = setInterval(async () => {
      const info = await request('status_dice', 'POST', {
        url: document.location.search,
      })
      setGamme({
        result: info.result,
        user: info.user,
        bets: info.bets,
        history: info.history,
        messages: info.messages,
      })
      if (info.result.time > 1) {
        setHistory(setHistoryBlock(info))
      }
    }, 500)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

    return (
        <Panel id={props.id}>
          <PanelHeader
            left={
              <PanelHeaderButton
                onClick={() => {
                  props.goMenu('main-menu')
                }}
              >
                <Icon28ChevronBack fill="white" />
              </PanelHeaderButton>
            }
          >
            Ice Cube
          </PanelHeader>
    
          <GameUserProfile game={game} fetchedUser={props.fetchedUser} />
    
          <div size="m" mode="shadow" className="football-result-card">
            <div className="football-game-text">История игр</div>
            <HorizontalScroll>
              <div style={{ display: 'flex' }}>{history}</div>
            </HorizontalScroll>
          </div>
    
            <GameIceCubeBoard game={game} />
            <GameIceCubeBet game={game}  />
            <GameIceCubeBetsBoard game={game} />

          <GameMessanger
            id="dice-messanger"
            fetchedUser={props.fetchedUser}
            messages={game.messages}
          />
        </Panel>
      )
}


export default IceCube