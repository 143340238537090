import React, { useEffect, useState } from 'react'

import { Group, Avatar, Cell } from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'
import format from '../../../hooks/formatCoins'

import { Icon28Users3Outline, Icon28AddSquareOutline } from '@vkontakte/icons'

import './Clan.css'

const Clan = (props) => {
  const [photos, setPhotos] = useState()

  useEffect(() => {
    const letUser = async () => {
      if (props.user.user.clan > 0) {
        const token = await bridge.send('VKWebAppGetAuthToken', {
          app_id: 7599871,
          scope: '',
        })

        const photos = await bridge.sendPromise('VKWebAppCallAPIMethod', {
          method: 'users.get',
          request_id: '324nnefj',

          params: {
            v: '5.95',
            user_ids: props.user.clan.id,
            fields: 'photo_200',
            access_token: token.access_token,
          },
        })
        setPhotos(photos)
      }
    }

    letUser()
  }, [])

  if (props.user.user.clan > 0) {
    return (
      <div className="profile-clan-element-fon">
        <div className="profile-clan-element">
          <div className="profile-clan-element-name">
            Ваша футбольная команда
          </div>
          <Group className="clan-profile-background">
            <Cell
              before={
                photos ? <Avatar src={photos.response[0].photo_200} /> : null
              }
              description={
                <div style={{ color: 'white' }}>{`Баланс - ${format(
                  (props.user.clan.balance * 1).toFixed(0)
                )} VK Coin`}</div>
              }
            >
              <div style={{ fontWeight: 500, color: 'white' }}>
                {props.user.clan.tag}{' '}
                {`[${props.user.clan.users.filter((x) => x.active).length}/22]`}
              </div>
            </Cell>
          </Group>
          <div className="profile-clan-win">
            <div className="profile-clan">
              <div>выиграли</div>
              <p>{format((props.user.clan.win * 1).toFixed(0))}</p>
            </div>
            <div className="profile-clan">
              <div>за неделю</div>
              <p>{format((props.user.clan.winDay * 1).toFixed(0))}</p>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="profile-clan-element-fon">
        <div className="profile-clan-element">
          <div className="profile-clan-element-name">
            Ваша футбольная команда
          </div>
          <div className="clan-profile-background">
            <p style={{ margin: 10, padding: 5, fontSize: '4vw' }}>
              Вы не состоите в футбольной команде!
            </p>
          </div>
          <div className="get-new-clan" style={{ color: 'rgb(170, 0, 255)' }}>
            <Icon28Users3Outline /> <div>Найти команду</div>
          </div>
          <div className="get-new-clan">
            <Icon28AddSquareOutline /> <div>Создать команду</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Clan
