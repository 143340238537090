import React, { useEffect, useState } from 'react'
import { Button, Panel, PanelHeader, Spinner } from '@vkontakte/vkui'
import bridge from '@vkontakte/vk-bridge'
import request from '../hooks/useHttp'
import md5 from 'md5'
import '../css/Repost.css'

const Repost = (props) => {
    const [message, setMessage] = useState(<Spinner />)

    useEffect(() => {
        const start = async () => {
            try {
                bridge.sendPromise('VKWebAppShowNativeAds', { ad_format: 'interstitial' })
                let req = await request('get_repost', 'POST', {
                    url: document.location.search,
                    key: md5(document.location.hash.split('_')[2])
                })
                setMessage(<p style={{ color: 'green'}}>{req.message}</p>)
            } catch (e) {
                console.log(e);
                setMessage(<p style={{ color: 'red'}}>{e.message || 'Произощла неизвестаня ошибка!'}</p>)
            }
        }

        start()
            
    }, [])

    return (
        <Panel>
            <PanelHeader>Репост</PanelHeader>
                <div className='repost_body'>
                    <div className='repost_window'>
                    <div className='repost_message'>
                        {message}
                    </div>
                    <Button onClick={props.closeApp}>Выйти</Button>
                    </div>
                </div>
        </Panel>
    )
}

export default Repost