import React, { useState, useEffect } from 'react'

import './GameFootballBoard.css'

import { Spinner } from '@vkontakte/vkui'
const GameFootballBoard = (props) => {
  const [res, setRes] = useState(null)

  useEffect(() => {
    if (props.game.result) {
      setRes(
        <div
          style={{
            padding: 7,
            fontSize: 40,
            fontWeight: 300,
            color: ' rgba(255, 166, 0, 0.904)',
          }}
        >
          <p
            style={{
              background: ' rgba(0, 0, 0, 0.604)',
              borderRadius: 10,
            }}
          >
            {props.game.result.time}
          </p>
        </div>
      )
      if (+props.game.result.time === 60) {
        setRes(
          <div
            style={{
              padding: 7,
              fontWeight: 300,
              color: ' rgba(255, 166, 0, 0.904)',
            }}
          >
            <p
              style={{
                background: ' rgba(0, 0, 0, 0.604)',
                borderRadius: 10,
              }}
            >
              Сделайте ставку первым!
            </p>
          </div>
        )
      }
      if (+props.game.result.time < 0) {
        setRes(
          <div
            style={{
              padding: 7,
              fontSize: 44,
              color: ' rgba(255, 166, 0, 0.904)',
            }}
          >
            <p
              style={{
                background: ' rgba(0, 0, 0, 0.604)',
                borderRadius: 10,
              }}
            >
              {`${props.game.result.goal1} : ${props.game.result.goal2}`}
            </p>
          </div>
        )
      }
    }
  }, [props.game])

  if (!props.game.result)
    return <Spinner size="medium" style={{ margin: '20px 0' }} />

  return (
    <div className="football-game-board-fon">
      <div className="football-game-board">
        <div className="command-football-photo-d">
          <div className="command-football-photo">
            <img
              src={`https://footballcoin.ru/1img/${props.game.result.command1.image}.png`}
              alt={props.game.result.command1.tag}
            />
          </div>
        </div>
        <div>{res}</div>
        <div className="command-football-photo-d">
          <div className="command-football-photo">
            <img
              src={`https://footballcoin.ru/1img/${props.game.result.command2.image}.png`}
              alt={props.game.result.command2.tag}
            />
          </div>
        </div>
        <p
          style={{
            background: 'var(--blue-comand-tag)',
            padding: 2,
            borderRadius: 5,
            margin: 3,
            color: 'white',
          }}
        >
          {props.game.result.command1.tag}
        </p>
        <div></div>
        <p
          style={{
            background: 'var(--red-comand-tag)',
            padding: 2,
            borderRadius: 5,
            margin: 0,
            color: 'white',
          }}
        >
          {props.game.result.command2.tag}
        </p>
      </div>
    </div>
  )
}

export default GameFootballBoard
