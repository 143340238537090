import React from 'react'

import format from '../../../hooks/formatCoins'
import getBonus from './GetBonus'
import Clan from './Clan.jsx'
import FreeCase from './FreeCase'

import { Link, Snackbar } from '@vkontakte/vkui'
import {
  Icon56MoneyTransferOutline,
  Icon56PaymentCardOutline,
} from '@vkontakte/icons'
import coin from '../../../css/coin.svg'
import giftbox from '../../../css/giftbox.svg'
import './Profile.css'
import { Icon20GiftCircleFillYellow } from '@vkontakte/icons';


const Profile = (props) => {
  return (
    <div className="profile-menu">
      <div className="profile-balance-button-fon">
        <div className="profile-balance">
          <p>БАЛАНС</p>
          <div className="profile-balance-items">
            <div>
              {format((props.user.user.balance * 1 || 0).toFixed(0))}{' '}
              <img src={coin} alt="coin" align="center" />
            </div>
            <div>
              {format((props.user.user.balanceBonus * 1 || 0).toFixed(0))}{' '}
              <img src={giftbox} alt="coin" align="center" />
            </div>
          </div>
        </div>
      </div>

      <div className="profile-coins-menu">
        <Link
          href={`https://vk.com/app6915965#x575680691_500000000_${props.user.user.id}_1`}
          target="_blank"
        >
          <div className="profile-coins-button-buy-fon">
            <div className="profile-coins-button-buy">
              <Icon56MoneyTransferOutline fill="#3498db" /> <p>ПОПОЛНИТЬ</p>
            </div>
          </div>
        </Link>
        <Link href={`https://vk.me/football.coin`} target="_blank">
          <div className="profile-coins-button-sale-fon">
            <div className="profile-coins-button-buy">
              <Icon56PaymentCardOutline fill="#9b59b6" /> <p>ВЫВЕСТИ</p>
            </div>
          </div>
        </Link>
      </div>

     { 1 === 2 ? <Link href={`https://vk.com/app8175004`} target="_blank">
        <div className='profile-banner'>
          <div className='profile-banner-center'>10 000 VK Coin за каждую команду!</div>
          <div className='profile-banner-bottom'>Football Quiz</div>
        </div>
      </Link> : null}

      <div
        className="profile-bonus-button"
        onClick={() => {
          getBonus(props.setSnackBar, document.location.search)
        }}
      >
        <Icon20GiftCircleFillYellow width={24} height={24}/>
        <p>РЕКЛАМНЫЙ БОНУС</p>
      </div>
      <FreeCase/>

      <Clan user={props.user} />
    </div>
  )
}

export default Profile
